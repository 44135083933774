class Card
{

	constructor()
	{
		var quote = "";
	}

	events()
	{
		var obj = this;


		// input
		$("#amount1000").click(function()
		{
			$("#buyPrice").val(1000).keyup();
			$("#otherAmount").hide();

			obj.buttonSelected(this);
		});

		$("#amount10000").click(function()
		{
			$("#buyPrice").val(10000).keyup();
			$("#otherAmount").hide();

			obj.buttonSelected(this);
		});

		$("#amountOther").click(function()
		{
			$("#otherAmount").show();
			$("#buyPrice").focus();

			obj.buttonSelected(this);
		});

		$("#maxBuy").click(function()
		{
			$("#buyPrice").val($("#buyPairAvailable").val()).keyup();
		});


		// change coin
		$(".dropdown-menu li").click(function()
		{
			var section = "";

			if (!$("#buyTab").hasClass("highlight"))
			{
				section = "&sell";
			}

			window.location.href = "/card-buy?market=zar-" + $(this).data("coin").toLowerCase() + section;
		});

		//setup before functions
		var typingTimer;                //timer identifier
		var doneTypingInterval = 2000;  //time in ms, 2 second for example

		$(document).on('keyup', '#buyPrice', function() {
			clearTimeout(typingTimer);
  			typingTimer = setTimeout(doneTyping, doneTypingInterval);
		});

		$(document).on('keydown', '#buyPrice', function() {
			clearTimeout(typingTimer);
		});

		function doneTyping() {
			var price = $("#buyPrice").val();
			var pair = $("#buyPair").val();
			var ticker = $("#buyTicker").val();
			var minAmount = parseFloat($("#minAmount").val());

			if (price < minAmount){
				swal.fire({
					title: "Buy Crypto with Your Credit/Debit Card",
					html: "You can not buy less than " + minAmount + " " + pair + " on with Your Credit/Debit Card. Please use main exchange for these orders.<br /><a href='/'>Go to Exchange</a>",
					type: "info",
					confirmButtonColor: "#6c757d"
				});
				return;
			}

			// validation
			$("#buyPrice").val(price);

			if ((obj.cleanNumber(price) <= 0) || (pair == "") || (ticker == ""))
			{
				return;
			}


			obj.loadQuote(pair, ticker, price);
			return false;
		}

		// $(document).on('propertychange change paste input', '#buyPrice', function()
		// {
		// 	doneTyping();
		// });


		// trade events
		$("#cardBuy").click(function()
		{
			var maxDiff = parseFloat($("#maxEstimateDifference").val());
			var originalEstimate = parseFloat($("#buyEstimate").val());
			var price = obj.cleanNumber($("#buyPrice").val());
			var pair = $("#buyPair").val();
			var ticker = $("#buyTicker").val();

			// recalculate estimate
			$.when
			(
				// reload orderbook
				obj.loadQuote(pair, ticker, price)
			)
			.done(function()
			{
				// check estimate diff
				var newEstimate = parseFloat($("#buyEstimate").val());

				// price diff more than previous
				if (newEstimate > originalEstimate)
				{
					// price % more than threshold
					if (Misc.relDiff(originalEstimate, newEstimate) >= maxDiff)
					{
						swal.fire({
							type: 'warning',
							title: "Estimate Update",
							html: "Significant price change, are you sure you want to continue?",
							showCancelButton: true,
							focusConfirm: true,
							cancelButtonText: 'Cancel'
						});
					}
					else
					{
						obj.confirmBuy();
					}
				}
				else
				{
					obj.confirmBuy();
				}
			});
			return false;
		});


		// misc events
		$("#cardInfo").click(function()
		{
			swal.fire({
				title: "Buy Crypto with Your Credit/Debit Card",
				html: "The amounts displayed are estimates based on current prices. <a href='https://altcointrader.zendesk.com/hc/en-gb/articles/360017362899' target='_blank'>Learn more</a> in our help centre.",
				type: "info",
				confirmButtonColor: "#6c757d"
			});
		});

		$(".goExchange").click(function()
		{
			window.location.href = "/";
		});

		$(".goBuyMore").click(function()
		{
			window.location.href = "/card-buy?market=zar-" + $("#buyTicker").val();
		});

		$(".goBalance").click(function()
		{
			window.location.href = "/balances";
		});
	}


	loadQuote(_pair, _ticker, _price)
	{
		var obj = this;
		var deferred = $.Deferred();

		$("#reportLoading").show();

		$.ajax(
		{
			url: "/services/simplex/quote.php",
			data:
			{
				"pair": _pair,
				"ticker": _ticker,
				"amount": _price
			},
			type: "POST",
			success: function(data)
			{
				if (data.Error.Code == 0)
				{
					obj.quote = data.Data.Quote;
					obj.estimateBuy();

					deferred.resolve();
				}
				else
				{
					Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
					deferred.reject();
				}

				$("#reportLoading").hide();
			},
			error: function()
			{
				Misc.errorMessage("Error loading order book");
				deferred.reject();

				$("#reportLoading").hide();
			}
		});

		return deferred.promise();
	}

	/**
	 * Confirmations
	 */
	confirmBuy()
	{
		var obj = this;
		var buyMax = parseFloat($("#buyMax").val());
		var minAmount = parseFloat($("#minAmount").val());
		var message = $("#buyConfirm").html();
		var price = parseFloat(obj.cleanNumber($('#buyPrice').val()));
		var buy = parseFloat(obj.cleanNumber($('#buyEstimate').val()));
		var pair = $("#buyPair").val().toUpperCase();

		// verify
		if (price > buyMax)
		{
			swal.fire({
				title: "Buy Crypto with Your Credit/Debit Card",
				html: "You can not buy more than " + buyMax + " " + pair + " on with Your Credit/Debit Card. Please use main exchange for these orders.<br /><a href='/'>Go to Exchange</a>",
				type: "info",
				confirmButtonColor: "#6c757d"
			});

			return;
		}

		if (price < minAmount)
		{
			swal.fire({
				title: "Buy Crypto with Your Credit/Debit Card",
				html: "You can not buy less than " + minAmount + " " + pair + " on with Your Credit/Debit Card. Please use main exchange for these orders.<br /><a href='/'>Go to Exchange</a>",
				type: "info",
				confirmButtonColor: "#6c757d"
			});

			return;
		}

		$("#buyForm").submit();
	}


	checkMax(amount, max, pair, ticker)
	{
		if (amount > max)
		{
			swal.fire({
				title: "Buy Crypto with Your Credit/Debit Card",
				html: "You can not buy more than " + max + " " + ticker + " on with Your Credit/Debit Card. Please use main exchange for these orders.<br /><a href='/'>Go to Exchange</a>",
				type: "info",
				confirmButtonColor: "#6c757d"
			});
			return;
		}
	}

	/**
	 * Calculate estimates
	 */
	estimateBuy()
	{
		/*
		console.log("**************************************************");
		console.log("estimate buys");
		console.log("**************************************************");
		*/

		var obj = this;


		var estimate = obj.quote.digital_money.amount;
		var buyAmount = obj.quote.digital_money.amount;;
		var buyPrice = obj.quote.fiat_money.total_amount;
		var buyPriceLessFee = obj.quote.fiat_money.base_amount;
		var quoteID = obj.quote.quote_id;
		var userID = obj.quote.user_id;

		var buyMax = parseFloat($("#buyMax").val());
		var pair = obj.quote.fiat_money.currency;
		var ticker = obj.quote.digital_money.currency;



		// display estimate
		$("#buyLessFee").val(buyPriceLessFee);
		$("#buyEstimate").val(buyPrice);
		$("#buyEstimateView span").html(estimate.toFixed(8));

		// save limit details
		var trimmedAmount = obj.trimEstimate(buyAmount, 8);
		$("#buyAmountCoins").val(trimmedAmount);
		$("#buyCoinPrice").val(buyPrice);
		$("#quoteID").val(quoteID);
		$("#userID").val(userID);
	}



	/**
	 * Remove unwanted characters from numeric input
	 */
	cleanNumber(value)
	{
		// convert to string
		value = "" + value;

		// max length
		var max = (value.indexOf(".") > -1) ? 21 : 12;
		var cleaned = (value.length >= max) ? value.substr(0, max) : value;

		// replace non numeric or period chars
		cleaned = cleaned.replace(/[^0-9\.]+/g, "");

		// format number
		if (cleaned.indexOf(".") > -1)
		{
			var parts = cleaned.split(".");
			cleaned = parts[0].match(/[0-9]{1,12}/) + "." + parts[1].match(/[0-9]{0,8}/);
		}

		// strip 0's in front
		cleaned = cleaned.replace(/^0+(?!\.|$)/, "");

		// replace empty string with 0
		cleaned = (cleaned != "") ? cleaned : 0;

		return cleaned;
	}

	/**
	 * trime estimation to avoid rounding issues
	 */
	trimEstimate(number, precision)
	{
		var result = number;

		if (("" + result).indexOf(".") !== -1)
		{
			var num = number.toString().split(".");
			result = num[0] + "." + num[1].substring(0, precision);
		}

		return result;
	}

	/**
	 * Highlight buttons
	 */
	buttonSelected(elm)
	{
		$(".btn").each(function()
		{
			$(this).removeClass("fresh-btn-hghlight");
		});

		$(elm).addClass("fresh-btn-hghlight");
	}

	/**
	 * List of coins
	 */
	coins()
	{
		var deferred = $.Deferred();
		var pair = $("#buyPair").val();
		var ticker = $("#buyTicker").val();

		$.ajax(
		{
			url: "/services/simplex/card-coins.php",
			data:
			{
				"pair": pair,
				"ticker": ticker
			},
			type: "POST",
			success: function(result)
			{
				if (result.Error.Code == 0)
				{
					if (result.Data != "")
					{
						$("#buyEstimateView div").html(result.Data);
						$("#sellTickerView").html(result.Data);

						deferred.resolve();
					}
				}
				else
				{
					Misc.errorMessage("Error Reading Coins");
					deferred.reject();
				}
			},
			error: function(error)
			{
				Misc.errorMessage("Error Reading Coins");
				deferred.reject();
			}
		});

		return deferred.promise();
	}

}
