
class Profile
{

	/**
	 * Process profile events
	 */
	events(physicalCountry, postalCountry)
	{
		var obj = this;

		// highlight current section
	    $("#profileNav ul li").click(function()
		{
			$("#profileNav ul li").removeClass("profileNavHighlight");
			$(this).addClass("profileNavHighlight");
		});

		// load page
		var url = document.location.toString();

	    if (url.match('#'))
	    {
	    	// change card
	        var card = $('.profile' + url.split('#')[1]);
	        card.addClass("show");

	        // background
	        $("#profileNav ul li")
	        .removeClass("profileNavHighlight")
	        .each(function(index)
	        {
	        	// highlight
	        	if ($(this).data("target") == ".profile" + url.split('#')[1])
	        	{
	        		$(this).addClass("profileNavHighlight");
	        	}

	        	// show affiliate
	        	if ((".profile" + url.split('#')[1] == ".profileAffiliate") &&
	        		($(this).data("target") == ".profileAffiliate"))
	        	{
	        		$(this).removeClass("d-none");
	        	}
	        });
	    }
	    else
	    {
	    	// show details by default
	    	$(".profileDetails").addClass("show");

	    	// highlight details background
	        $("#profileNav ul li")
	        .each(function(index)
	        {
	        	if ($(this).data("target") == ".profileDetails")
	        	{
	        		$(this).addClass("profileNavHighlight");
	        	}
	        });
	    }

	    // cell setup
	    $("#usercell").intlTelInput(
	    {
	    	preferredCountries: ['za', 'ls', 'na'],
	    	initialCountry: 'za',
	    	formatOnDisplay: false,
	    	utilsScript: "/includes/client/external/utils.js"
	    });

	    $(document).on('click','#update_cell', function()
	    {
	    	$('#internationalNumber').val($("#usercell").intlTelInput("getNumber"));
	    	$('#countryCode').val($("#usercell").intlTelInput("getSelectedCountryData")['dialCode']);
	    	$('form#cellupdate').submit();
	    });

		$(document).on('click','#createVerification', function()
		{
			obj.createVerification();
		});

	    // validation & confirmation
	    $(document).on('input paste change', '.details', function()
		{
			obj.validateDetails(this, this);
		});

	    // process personal details update
	    $(".processDetails").click(function(e)
		{
			e.preventDefault();

			// check if valid
			var form = $(this).closest("form");
			var block = false;

			$(".details", "#detailsForm").each(function()
			{
				if (!obj.validateDetails(this))
				{
					block = true;
				}
			});

			if (!block)
			{
				// confirm
				swal.fire({
					text: "Are you sure you want to update your details?",
					type: "question",
					showCancelButton: true,
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel'
				})
				.then((result) =>
				{
					if (result.value)
					{
						form.submit();
					}
				});
			}
		});

	    // process security updates
	    $(".processSecurity").click(function(e)
		{
			e.preventDefault();

			// check if valid
			var form = $(this).closest("form");
			var block = false;

			$(".details", "#securityForm").each(function()
			{
				if (!obj.validateDetails(this))
				{
					block = true;
				}
			});

			// password match
			if ($("#user_password_new").val() != $("#user_password_repeat").val())
			{
				$("#user_password_repeat").parent().parent().find(".input-status")
				.html("<i class='fas fa-exclamation-circle'></i>");

				$("#user_password_repeat").parent().find("small")
				.html("New Passwords don't match");

				block = true;
			}

			if (!block)
			{
				// confirm
				swal.fire({
					text: "Are you sure you want to update your Security details?",
					type: "question",
					showCancelButton: true,
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel'
				})
				.then((result) =>
				{
					if (result.value)
					{
						form.submit();
					}
				});
			}
		});

		$(".close").unbind();
		$(document).on("click", ".close", function()
		{
			obj.closeModal();
		});

	    // disable button control
	    $("#agree_disable").change(function()
	    {
	    	$(".disableAccount").attr("disabled", !$("#agree_disable").is(":checked"));
	    });

	    // validate disable request
	    $(".disableAccount").click(function(e)
		{
			e.preventDefault();

			var form = $(this).closest("form");

			if ($("#agree_disable").is(":checked"))
			{
				// confirm
				swal.fire({
					text: "Are you sure you want to disable your Account?",
					type: "question",
					showCancelButton: true,
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel'
				})
				.then((result) =>
				{
					if (result.value)
					{
						form.submit();
					}
				});
			}
		});

	    // process affiliate
	    $(".processAffiliate").click(function(e)
		{
			e.preventDefault();

			// check if valid
			var form = $(this).closest("form");
			var block = false;

			$(".details", "#affiliateForm").each(function()
			{
				if (!obj.validateDetails(this))
				{
					block = true;
				}
			});

			if (!block)
			{
				// confirm
				swal.fire({
					text: "Are you sure you want to join this affiliate?",
					type: "question",
					showCancelButton: true,
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel'
				})
				.then((result) =>
				{
					if (result.value)
					{
						form.submit();
					}
				});
			}
		});
	}

	createVerification()
	{
		$("#reportLoading").show();

		$.ajax(
		{
			url: "/services/user/create-verification.php",
			type: "POST",
			success: function(data)
			{
				if (data.Error.Code == 0)
				{
					if (data.Link != "")
					{
						// redirect
						window.location.href = data.Link;
					}
					else
					{
						Misc.errorMessage("Error Generating Verification Link");
					}
				}
				else
				{
					Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
				}
			},
			error: function()
			{
				Misc.errorMessage("Error Generating Verification Link");
			}
		});
	}

	closeModal()
	{
		$('.modal').modal("hide");
	}

	/**
	 * Validation control
	 */
	validateDetails(elm)
	{
		// ignore empty
		if (elm == null) return true;

		var valid = false;

		if(!elm.checkValidity())
		{
			$(elm).parent().parent().find(".input-status")
			.html("<i class='fas fa-exclamation-circle'></i>");

			$(elm).parent().find("small")
			.html("Invalid details");
		}
		else
		{
			$(elm).parent().parent().find(".input-status")
			.html("<i class='fas fa-check-circle'></i>");

			$(elm).parent().find("small").html("");

			valid = true;
		}

		return valid;
	}

}
