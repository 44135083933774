
class Trading
{

	/**
	 * Constructor
	 */
	constructor(tradingPair, tradingSymbol, coinSymbol)
	{
		this.tradingPair = tradingPair;
		this.tradingSymbol = tradingSymbol;
		this.coinSymbol = coinSymbol;
		this.minSell = parseFloat($("#minimumSell").val());
		this.minBuy = parseFloat($("#minimumBuy").val());

		// start basic charts for desktop
		if (Misc.viewportSize("md") || Misc.viewportSize("lg") || Misc.viewportSize("xl"))
		{
			var highChart = new HighChart(tradingPair, coinSymbol);
		}

		// triggers
		this.events();
		this.sliderStatus();
		this.loadAdvancedCharts();

		// start live updates - config control
		var enableLiveUpdates = 1;
		if ($("#liveUpdates").length)
		{
			enableLiveUpdates = $("#liveUpdates").val();
		}

		var tradingUpdates;
		if (enableLiveUpdates == 1)
		{
			console.log("live updates enabled");
			tradingUpdates = new TradingUpdates(tradingPair, tradingSymbol, coinSymbol);
		}

		// apply filter to coin list
		this.searchCoinList(this.loadCoinList());
		this.favouritesDisplay();
	}

	sliderStatus()
	{
		var buyAvailable = parseFloat($("#availableBuy").val() != "" ? $("#availableBuy").val() : 0);
	    if (buyAvailable == 0)
		{
			$("#buySlide").slider("disable");
			$("#buyStopSlide").slider("disable");
		}

	    var sellAvailable = parseFloat($("#availableSell").val() != "" ? $("#availableSell").val() : 0);
	    if (sellAvailable == 0)
		{
			$("#sellSlide").slider("disable");
			$("#stopSellSlide").slider("disable");
		}
	}

	/**
	 * Event handlers
	 */
	events()
	{
		var obj = this;

		// populate from current order list
		$(document).on('click', '.orderUdSell', function()
		{
			$('.buyPr').val($(this).find(".orderUdSPr").text());
			$('.buyAmt').val($(this).find(".orderUdSAm").text());
			$('.buyAmt').trigger("change");
		});

		$(document).on('click', '.orderUdBuy', function()
		{
			$('.sellPr').val($(this).find(".orderUdBPr").text());
			$('.sellAmt').val($(this).find(".orderUdBAm").text());
			$('.sellAmt').trigger("change");
		});

		// tabs
		$(".tradeTab").click(function()
		{
			var type = $(this).data("type");

			$("#limitTab" + type).removeClass("text-muted");
			$("#stopLimitTab" + type).addClass("tabhightlight");

			$("#limitTab" + type).removeClass("tabhightlight");
			$("#stopLimitTab" + type).addClass("text-muted");

			$("#limitView" + type).show();
			$("#stopLimitView" + type).hide();
		});

		$(".tradeStopTab").click(function()
		{
			var type = $(this).data("type");

			$("#stopLimitTab" + type).removeClass("text-muted");
			$("#limitTab" + type).addClass("tabhightlight");

			$("#stopLimitTab" + type).removeClass("tabhightlight");
			$("#limitTab" + type).addClass("text-muted");

			$("#stopLimitView" + type).show();
			$("#limitView" + type).hide();
		});

		// focus/blur events
		$('.buyAmt, .buyPr, .sellAmt, .sellPr, #buytotal, #buyStopTotal, #selltotal, #sellStopTotal').on("focus", function()
		{
			if ($(this).val() == 0)
			{
				$(this).val("");
			}
		});

		$('.buyAmt, .buyPr, .sellAmt, .sellPr, #buytotal, #buyStopTotal, #selltotal, #sellStopTotal').on("blur", function()
		{
			if ($(this).val() == "")
			{
				$(this).val("0");
			}
		});

		// input events
		$(document).on('propertychange change paste keyup input', '.buyAmt, .buyPr', function()
		{
			var fee = obj.cleanNumber($("#fee").val());
			var amount = obj.cleanNumber($('.buyAmt').val());
			var price = obj.cleanNumber($('.buyPr').val());

			var total = parseFloat((amount * price).toFixed(8));

			// min
			if ((total > 0) && (total < obj.minBuy))
			{
				$("#buymin").removeClass("d-none");
			}
			else
			{
				$("#buymin").addClass("d-none");
			}

			$('.buyAmt').val(amount);
			$('.buyPr').val(price);
			$('#buytotal').val(total.toFixed(8));
			$('#buyfee').html(((amount / 100) * fee).toFixed(8));

			obj.toggleButton("#order_buy", amount, obj.minBuy);
			obj.buySlideCalc(total);
		});

		$(document).on('propertychange change paste keyup input', '.sellAmt, .sellPr', function()
		{
			var fee = obj.cleanNumber($("#fee").val());
			var amount = obj.cleanNumber($('.sellAmt').val());
			var price = obj.cleanNumber($('.sellPr').val());

			var total = parseFloat((amount * price).toFixed(8));

			// min
			if ((total > 0) && (total < obj.minSell))
			{
				$("#sellmin, #sellstopmin").removeClass("d-none");
			}
			else
			{
				$("#sellmin, #sellstopmin").addClass("d-none");
			}

			$('.sellAmt').val(amount);
			$('.sellPr').val(price);
			$('#selltotal, #sellStopTotal').val(total.toFixed(8));
			$('#sellfee').html(((total / 100) * fee).toFixed(8));

			obj.toggleButton("#order_sell", amount, obj.minSell);
			obj.sellSlideCalc(amount);
		});

		$(document).on('propertychange change paste keyup input', '#buytotal, #buyStopTotal', function()
		{
			var fee = obj.cleanNumber($("#fee").val());
			var price = obj.cleanNumber($('.buyPr').val());
			var total = obj.cleanNumber($('#buytotal').val());

			var amount = (total / price).toFixed(8);

			// min
			if ((total > 0) && (parseFloat(total) < obj.minBuy))
			{
				$("#buymin, #buystopmin").removeClass("d-none");
			}
			else
			{
				$("#buymin, #buystopmin").addClass("d-none");
			}

			$('.buyAmt').val(amount);
			$('.buyPr').val(price);
			$('#buytotal, #buyStopTotal').val(total);
			$('#buyfee').html(((amount / 100) * fee).toFixed(8));

			obj.toggleButton("#order_buy", amount, obj.minBuy);
			obj.buySlideCalc(total);
		});

		$(document).on('propertychange change paste keyup input', '#selltotal, #sellStopTotal', function()
		{
			var fee = obj.cleanNumber($("#fee").val());
			var price = obj.cleanNumber($('.sellPr').val());
			var total = obj.cleanNumber($('#selltotal').val());

			var amount = (total / price).toFixed(8);

			// min
			if ((total > 0) && (parseFloat(total) < obj.minSell))
			{
				$("#sellmin, #sellstopmin").removeClass("d-none");
			}
			else
			{
				$("#sellmin, #sellstopmin").addClass("d-none");
			}

			$('.sellAmt').val(amount);
			$('.sellPr').val(price);
			$('#selltotal, #sellStopTotal').val(total);
			$('#sellfee').html(((total / 100) * fee).toFixed(8));

			obj.toggleButton("#order_sell", amount, obj.minSell);
			obj.sellSlideCalc(amount);
		});

		// sliders
	    $("#buySlide, #buyStopSlide").slider(
	    {
	    	value: 0,
	    	min: 0,
	    	max: 100,
	    	step: 1,
	        create: function()
	        {
	        	$("#buyHandle, #buyStopHandle").text($(this).slider("value") + " %");
	        },
	    	slide: function(event, ui)
	    	{
	    		var available = parseFloat($("#availableBuy").val() != "" ? $("#availableBuy").val() : 0);
	    		var perc = available * (ui.value / 100);

	    		$("#buyHandle, #buyStopHandle").text(ui.value + " %");
	    		$("#buytotal, #buystoptotal").val(perc.toFixed(2)).keyup();

	    		$("#buySlide, #buyStopSlide").slider("value", ui.value);
	    	}
	    });

	    $("#sellSlide, #sellStopSlide").slider(
	    {
	    	value: 0,
	    	min: 0,
	    	max: 100,
	    	step: 1,
	        create: function()
	        {
	        	$("#sellHandle, #sellStopHandle").text($(this).slider("value") + " %");
	        },
	    	slide: function(event, ui)
	    	{
	    		var available = parseFloat($("#availableSell").val() != "" ? $("#availableSell").val() : 0);
	    		var perc = available * (ui.value / 100);

	    		$("#sellHandle, #sellStopHandle").text(ui.value + " %");
	    		$(".sellAmt, #sellStopAmountCoins").val(perc.toFixed(8)).keyup();

	    		$("#sellSlide, #sellStopSlide").slider("value", ui.value);
	    	}
	    });

	    $("#buySlide div, #buyStopSlide div").click(function()
        {
        	$("#buySlide, #buyStopSlide").slider("value", $(this).data("loc"));
        });

	    $("#sellSlide div, #sellStopSlide div").click(function()
        {
        	$("#sellSlide, #sellStopSlide").slider("value", $(this).data("loc"));
        });

	    // button events
		$("#order_buy").click(function()
		{
			var message = $("#buyConfirm").html();
			var fee = obj.cleanNumber($("#fee").val());
			var amount = obj.cleanNumber($('.buyAmt').val());
			var price = obj.cleanNumber($('.buyPr').val());

			var total = (amount * price).toFixed(8);
			var totalFee = (amount / 100 * fee).toFixed(8);

			message = message.replace("{amount}", amount);
			message = message.replace("{price}", price);
			message = message.replace("{total}", total);
			message = message.replace(/{coinSymbol}/g, obj.coinSymbol.toUpperCase());
			message = message.replace("{totalFee}", totalFee);

			swal.fire({
				type: 'question',
				title: "Confirm Buy Order",
				html: message,
				showCancelButton: true,
				focusConfirm: true,
				confirmButtonText: 'Buy',
				cancelButtonText: 'Cancel'
			})
			.then((result) =>
			{
				if (result.value)
				{
					$("#buyform").submit();
				}
			});
		});

		$("#buyStopPlaceOrder").click(function()
		{
			var message = $("#buyStopConfirm").html();
			var fee = obj.cleanNumber($("#fee").val());
			var amount = obj.cleanNumber($('#buyStopAmountCoins').val());
			var price = parseFloat(obj.cleanNumber($('#buyStopCoinPrice').val()));
			var stop = parseFloat(obj.cleanNumber($('#buyStopPrice').val()));

			var total = (amount * price).toFixed(8);
			var totalFee = (amount / 100 * fee).toFixed(8);

			// stop limits
			console.log(stop.toFixed(8) + " < " + price.toFixed(8));
			if (stop < price)
			{
				swal.fire({
					type: 'error',
					title: "Order Error",
					html: "Stop must be greater than Price",
					focusConfirm: true,
					confirmButtonText: 'Ok'
				})

				return;
			}

			message = message.replace("{amount}", amount);
			message = message.replace("{price}", price);
			message = message.replace("{stop}", stop);
			message = message.replace("{total}", total);
			message = message.replace(/{coinSymbol}/g, obj.coinSymbol.toUpperCase());
			message = message.replace("{totalFee}", totalFee);

			swal.fire({
				type: 'question',
				title: "Confirm Buy Stop Order",
				html: message,
				showCancelButton: true,
				focusConfirm: true,
				confirmButtonText: 'Buy',
				cancelButtonText: 'Cancel'
			})
			.then((result) =>
			{
				if (result.value)
				{
					$("#buystopform").submit();
				}
			});
		});

		$("#order_sell").click(function()
		{
			var message = $("#sellConfirm").html();
			var fee = obj.cleanNumber($("#fee").val());
			var amount = obj.cleanNumber($('.sellAmt').val());
			var price = obj.cleanNumber($('.sellPr').val());

			var total = (amount * price).toFixed(8);
			var totalFee = (amount * (price / 100) * fee).toFixed(8);

			message = message.replace("{amount}", amount);
			message = message.replace("{price}", price);
			message = message.replace("{total}", total);
			message = message.replace(/{coinSymbol}/g, obj.coinSymbol.toUpperCase());
			message = message.replace("{totalFee}", totalFee);

			swal.fire({
				type: 'question',
				title: "Confirm Sell Order",
				html: message,
				showCancelButton: true,
				focusConfirm: true,
				confirmButtonText: 'Sell',
				cancelButtonText: 'Cancel'
			})
			.then((result) =>
			{
				if (result.value)
				{
					$("#sellform").submit();
				}
			});
		});

		$("#sellStopPlaceOrder").click(function()
		{
			var message = $("#sellStopConfirm").html();
			var fee = obj.cleanNumber($("#fee").val());
			var amount = obj.cleanNumber($('#sellStopAmountCoins').val());
			var price = parseFloat(obj.cleanNumber($('#sellStopCoinPrice').val()));
			var stop = parseFloat(obj.cleanNumber($('#sellStopPrice').val()));

			var total = (amount * price).toFixed(8);
			var totalFee = (amount / 100 * fee).toFixed(8);

			// stop limits
			console.log(stop.toFixed(8) + " > " + price.toFixed(8));
			if (stop > price)
			{
				swal.fire({
					type: 'error',
					title: "Order Error",
					html: "Stop must be less than Price",
					focusConfirm: true,
					confirmButtonText: 'Ok'
				})

				return;
			}

			message = message.replace("{amount}", amount);
			message = message.replace("{price}", price);
			message = message.replace("{stop}", stop);
			message = message.replace("{total}", total);
			message = message.replace(/{coinSymbol}/g, obj.coinSymbol.toUpperCase());
			message = message.replace("{totalFee}", totalFee);

			swal.fire({
				type: 'question',
				title: "Confirm Sell Stop Order",
				html: message,
				showCancelButton: true,
				focusConfirm: true,
				confirmButtonText: 'Sell',
				cancelButtonText: 'Cancel'
			})
			.then((result) =>
			{
				if (result.value)
				{
					$("#sellstopform").submit();
				}
			});
		});

		$(document).on('keyup', '#market_search', function()
		{
			obj.searchCoinList($(this).val().toUpperCase());
		});

		$(".filter").click(function()
		{
			obj.searchCoinList($(this).data("filter").toUpperCase());
		});

		$(".addFav").click(function()
		{
			if ($(this).hasClass("text-warning"))
			{
				$(this).removeClass("text-warning");
				$(this).removeClass("fas");
				$(this).addClass("far");
			}
			else
			{
				$(this).addClass("text-warning");
				$(this).addClass("fas");
				$(this).removeClass("far");
			}

			obj.saveCoinListFavourites($(this).data("fav"));
		});

		$('#coin_market_table tr .colLink').click(function(e)
		{
			var href = $(this).parent().find("a").attr("href");
			if (href)
			{
				window.location = href;
			}
		});

		$("#chart-nav a").on("click", function (e)
		{
			e.preventDefault();
			$(this).tab("show");
		});

		$("#chart-nav a").on("shown.bs.tab", function (e)
		{
			let currentTab = $(e.currentTarget);
			let tabId = currentTab.attr("id");

			if (tabId === "chart-tab-advanced")
			{
				// setup advanced chart
				obj.tradingView = new TradingViewCharts(obj.tradingPair, obj.coinSymbol);
				obj.saveCharts("advanced");
		    }
			else
			{
				obj.tradingView.kill();
				obj.saveCharts("basic");
		    }
		});

		$("#showMarkets").click(function()
		{
			$("#marketBlock").toggleClass("d-none");
			$("#marketsBlock").toggleClass("d-none");

			if ($("#marketBlock").hasClass("d-none"))
			{
				$("#showMarkets span").html("Show Markets");
			}
			else
			{
				$("#showMarkets span").html("Hide Markets");
			}
		});

		$("#showCharts").click(function()
		{
			$("#chartBlock").toggleClass("d-none");

			if ($("#chartBlock").hasClass("d-none"))
			{
				$("#showCharts span").html("Show Charts");
			}
			else
			{
				$("#showCharts span").html("Hide Charts");
			}

			if (obj.tradingView == null)
			{
				$("#chart-basic").removeClass("active");
				$("#chart-advanced").addClass("active");

				// start advanced chart
				obj.tradingView = new TradingViewCharts(obj.tradingPair, obj.coinSymbol);
				obj.saveCharts("advanced");
			}
			else
			{
				obj.tradingView.kill();
				obj.saveCharts("basic");
		    }
		});

		// info buttons
		$(".more-info").unbind();
		$(document).on("click", ".more-info", function()
		{
			swal.fire({
				title: "Information",
				text: $("#info_" +  $(this).data("info")).html(),
				type: "info",
				confirmButtonText: 'OK'
			})
		});
	}

	buySlideCalc(_total)
	{
		var available = parseFloat($("#availableBuy").val() != "" ? $("#availableBuy").val() : 0);
		var buySlideVal = 0;

		if (available != 0)
		{
			buySlideVal = Math.ceil((_total / available) * 100);

			if (buySlideVal < 0)
			{
				buySlideVal = 0;
			}

			if (buySlideVal > 100)
			{
				buySlideVal = 100;
			}
		}

		$("#buySlide").slider("value", buySlideVal);
		$("#buyHandle").text(buySlideVal + " %");
	}

	sellSlideCalc(_amount)
	{
		var available = parseFloat($("#availableSell").val() != "" ? $("#availableSell").val() : 0);
		var sellSlideVal = 0;

		if (available != 0)
		{
			sellSlideVal = Math.ceil((_amount / available) * 100);

			if (sellSlideVal < 0)
			{
				sellSlideVal = 0;
			}

			if (sellSlideVal > 100)
			{
				sellSlideVal = 100;
			}
		}

		$("#sellSlide").slider("value", sellSlideVal);
		$("#sellHandle").text(sellSlideVal + " %");
	}

	toggleButton(btn, amount, min)
	{
		/*
		if (amount >= min)
		{
			$(btn).prop("disabled", false);
		}
		else
		{
			$(btn).prop("disabled", true);
		}
		*/
	}

	buttonSelected(elm, btnclass)
	{
		$(btnclass).each(function()
		{
			$(this).removeClass("btnhighlight");
		});

		$(elm).addClass("btnhighlight");
	}

	/**
	 * Chart save & load
	 */
	saveCharts(type)
	{
		document.body.setAttribute("data-charts", type);
		localStorage.setItem("charts", type);
		document.cookie = "charts=" + type + "; expires=Thu, 01 Jan 2090 00:00:00 UTC; path=/;";
	}

	loadAdvancedCharts()
	{
		if (Misc.getCookie("charts") == "advanced")
		{
			// only for desktop, basic doesnt show on mobile
			$("#chart-tab-advanced").click();
		}
	}

	/**
	 * Coin list filter
	 */
	searchCoinList(filter)
	{
		//console.log("searchCoinList " + filter);

		if (filter == "FAVOURITES")
		{
			$("#market_search").val("");
			this.filterListFavourites();
		}
		else if ((filter == "ALL") || (filter == ""))
		{
			$("#market_search").val("");
			this.filterList("");
		}
		else if (filter == "XZAR")
		{
			$("#market_search").val("");
			this.filterList("ZAR");
		}
		else if (filter == "USDT")
		{
			$("#market_search").val("");
			this.filterList("USDT");
		}
		else
		{
			// set search box if not existing filter
			$("#market_search").val(filter);
			this.filterList(filter.toUpperCase());
		}

		// save filter for later
		this.saveCoinList(filter);

		// update display
		this.filterDisplay();
	}

	filterList(filter)
	{
		// console.log("filterList " + filter);

		$("#coin_market_table > tbody  > tr").each(function(index, tr)
		{
			if ($("td:nth-child(2)", this).html().toUpperCase().indexOf(filter) > -1)
			{
				tr.style.display = "";
			}
			else
			{
				tr.style.display = "none";
			}
		});
	}

	filterListFavourites()
	{
		var favourites = this.loadCoinListFavourites();

		//console.log("filterListFavourites " + favourites);

		$("#coin_market_table > tbody  > tr").each(function(index, tr)
		{
			//console.log("row " + $("td:nth-child(1) i", this).data("fav"));

			if (favourites.indexOf($("td:nth-child(1) i", this).data("fav")) > -1)
			{
				tr.style.display = "";
			}
			else
			{
				tr.style.display = "none";
			}
		});
	}

	filterDisplay()
	{
		var styleClass = $("#jsCoinSymbol").val() + "-text-color";
		var filter = this.loadCoinList();
		if (filter == "")
		{
			filter = "ALL";
		}

		// console.log("filterDisplay " + filter);

		$(".filter").each(function()
		{
			// toggle display
			if (($(this).data("filter").toUpperCase() == filter) &&
				!($(this).hasClass(styleClass)))
			{
				// console.log("add class " + filter);

				if ($(this).data("filter") == "FAVOURITES")
				{
					$(this).addClass("text-warning");

					// change icon
					$("i", this).addClass("fas");
					$("i", this).removeClass("far");
				}
				else
				{
					$(this).addClass(styleClass);
				}
			}
			else
			{
				// console.log("remove class " + filter);

				if ($(this).data("filter") == "FAVOURITES")
				{
					$(this).removeClass("text-warning");

					// change icon
					$("i", this).removeClass("fas");
					$("i", this).addClass("far");
				}
				else
				{
					$(this).removeClass(styleClass);
					$(this).removeClass("text-warning");
				}
			}
		});
	}

	favouritesDisplay()
	{
		var styleClass = "text-warning";
		var favourites = this.loadCoinListFavourites();

		// console.log("display favourites " + favourites);

		$("#coin_market_table > tbody  > tr").each(function(index, tr)
		{
			// console.log("row " + $("td:nth-child(1) i", this).data("fav"));

			if ((favourites.indexOf($("td:nth-child(1) i", this).data("fav")) > -1) &&
				!($("td:nth-child(1) i", this).hasClass(styleClass)))
			{
				$("td:nth-child(1) i", this).addClass(styleClass);

				// change icon
				$("td:nth-child(1) i", this).addClass("fas");
				$("td:nth-child(1) i", this).removeClass("far");
			}
			else
			{
				$("td:nth-child(1) i", this).removeClass(styleClass);

				// change icon
				$("td:nth-child(1) i", this).removeClass("fas");
				$("td:nth-child(1) i", this).addClass("far");
			}
		});
	}

	/**
	 * Coin list filter
	 */
	saveCoinList(filter)
	{
		// console.log("saveCoinList " + filter);

		document.body.setAttribute("data-coinList", filter);
		localStorage.setItem("coinList", filter);
		document.cookie = "coinList=" + filter + "; expires=Thu, 01 Jan 2090 00:00:00 UTC; path=/;";
	}

	loadCoinList()
	{
		return Misc.getCookie("coinList");
	}

	/**
	 * Save coin list favourites
	 */
	saveCoinListFavourites(favourite)
	{
		// read existing
		var favourites = this.loadCoinListFavourites();

		// manage list
		if (favourites == "")
		{
			favourites = [];
		}

		var index = favourites.indexOf(favourite);

	    if (index === -1)
	    {
	    	favourites.push(favourite);
	    }
	    else
	    {
	    	favourites.splice(index, 1);
	    }

		// console.log("favs " + favourites);

		// save
		var jsonFavs = JSON.stringify(favourites);

		document.body.setAttribute("data-coinListFav", jsonFavs);
		localStorage.setItem("coinListFav", jsonFavs);
		document.cookie = "coinListFav=" + jsonFavs + "; expires=Thu, 01 Jan 2090 00:00:00 UTC; path=/;";
	}

	loadCoinListFavourites()
	{
		var favourites = Misc.getCookie("coinListFav");
		if (favourites != "")
		{
			favourites = JSON.parse(favourites);
		}

		return favourites;
	}

	/**
	 * Remove unwanted characters from numeric input
	 */
	cleanNumber(value)
	{
		// max length
		//var max = (value.indexOf(".") > -1) ? 21 : 12;
		var max = (value.indexOf(".") > -1) ? 24 : 24;
		var cleaned = (value.length >= max) ? value.substr(0, max) : value;

		// replace non numeric or period chars
		cleaned = cleaned.replace(/[^0-9\.]+/g, "");

		// format number
		if (cleaned.indexOf(".") > -1)
		{
			var parts = cleaned.split(".");
			var whole = 0;

			if (parts[0] != "")
			{
				//whole = parts[0].match(/[0-9]{1,12}/);
				whole = parts[0].match(/[0-9]{1,24}/);
			}

			cleaned = whole + "." + parts[1].match(/[0-9]{0,8}/);
		}

		// strip 0's in front
		cleaned = cleaned.replace(/^0+(?!\.|$)/, "");

		// replace empty string with 0
		cleaned = (cleaned != "") ? cleaned : 0;

		return cleaned;
	}

}
