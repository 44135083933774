
class Savings
{

	/** ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	 * Events
	 */

	events()
	{
		var obj = this;

		// load page
		var url = document.location.toString();

	    if (url.match('#'))
	    {
	    	var type = url.split('#')[1].toUpperCase();

	    	if (type == "XZAR")
	    	{
	    		type = "xZAR";
	    	}

	    	// change card
	        $('#sec' + type).addClass("show");
	    }

		// navigation
		$(document).on("click", ".dropdown-menu li", function()
		{
			$(".custom-dropdown").find(".btn").html($(".custom-dropdown ul").find("[data-coin='" + $(this).data("coin") + "']").html());

			// reset available
			$("#currentOption").val($(".custom-dropdown ul").find("[data-coin='" + $(this).data("coin") + "']").data("option"));
			$("#currentMinimum").val($(".custom-dropdown ul").find("[data-coin='" + $(this).data("coin") + "']").data("minimum"));
			$("#currentMaximum").val($(".custom-dropdown ul").find("[data-coin='" + $(this).data("coin") + "']").data("maximum"));
			$("#available").html($(".custom-dropdown ul").find("[data-coin='" + $(this).data("coin") + "']").data("available"));
			$("#availableTicker").html($(".custom-dropdown ul").find("[data-coin='" + $(this).data("coin") + "']").data("coin"));
			$("#saveAmount").val("");
		});

		$('.collapse').on('shown.bs.collapse', function(e)
		{
			if ($("." + $(this).data("section") + "interest").length == 0) return;

			obj.calculateInterest($("." + $(this).data("section") + "interest"));
			obj.calculateInterest($("." + $(this).data("section") + "interest-equiv"));
		});

		// general management
		$(".add").unbind();
		$(document).on("click", ".add", function()
		{
			var ticker = $(this).data("ticker");

			$.ajax(
			{
				url: "/services/savings/show-add.php",
				data:
				{
					"ticker": ticker
				},
				type: "POST",
				success: function(data)
				{
					if (data.Error.Code == 0)
					{
						$("#modalContainer").html(data.Modal);
						$("#modalSavingsAdd").modal("show");
					}
					else
					{
						Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
					}
				},
				error: function(xhr)
				{
					if (xhr.status == 429)
					{
						Misc.errorMessage("Too Many Requests");
					}
					else
					{
						Misc.errorMessage("Error Showing Add");
					}
				}
			});
		});

		$(".claiminterest").unbind();
		$(document).on("click", ".claiminterest", function()
		{
			var error = "";
			var ticker = $(this).data("ticker");
			var min = parseFloat(Misc.cleanNumber($(this).data("min")));
			var interest = $(".option" + $(this).data("id")).closest("div").find(".interest").data("total");
			var claimHourlyLimit = $("#claimHourlyLimit").val();

			// validate
			if (interest < min)
			{
				error = "The minimum reward claim is " + min + " " + ticker;
			}

			if (error != "")
			{
				swal.fire({
					title: "Error",
					text: error,
					type: "error",
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					allowEscapeKey: false,
					closeOnClickOutside: false
				});

				return;
			}

			// confirm
			swal.fire({
				title: "Claim rewards",
				html: "Claimed rewards will be deposited into your wallet.<br /><br />You will have to wait " + claimHourlyLimit + " hours before you can claim again.",
				type: "question",
				showCancelButton: true,
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				allowOutsideClick: false,
				allowEscapeKey: false,
				closeOnClickOutside: false
			})
			.then((result) =>
			{
				if (result.value)
				{
					obj.claimInterest($(this).data("id"), $(this).data("random"));
				}
			});
		});

		$(".claimexit").unbind();
		$(document).on("click", ".claimexit", function()
		{
			var claimHourlyLimit = $("#claimHourlyLimit").val();

			swal.fire({
				title: "Claim & Exit",
				html: "Capital and rewards will be deposited into your wallet and the savings account closed.<br /><br />You will have to wait " + claimHourlyLimit + " hours before you can claim again.",
				type: "warning",
				showCancelButton: true,
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				allowOutsideClick: false,
				allowEscapeKey: false,
				closeOnClickOutside: false
			})
			.then((result) =>
			{
				if (result.value)
				{
					obj.claimExit($(this).data("id"), $(this).data("random"));
				}
			});
		});

		$("#saveAmount").unbind();
		$(document).on('propertychange change paste keyup input', '#saveAmount', function()
		{
			// force only valid currency values
			$("#saveAmount").val(Misc.cleanNumber($("#saveAmount").val()));
		});

		$("#addFunds").unbind();
		$(document).on("click", "#addFunds", function()
		{
			var error = "";
			var amount = parseFloat(Misc.cleanNumber($("#saveAmount").val()));
			var minimum = parseFloat(Misc.cleanNumber($("#currentMinimum").val()));
			var maximum = parseFloat(Misc.cleanNumber($("#currentMaximum").val()));

			// validate
			if ((amount < minimum) ||
				(amount > maximum))
			{
				error = "Please enter an amount between<br />" + minimum + " - " + maximum + " " + $("#availableTicker").html();
			}

			if (!$("#agreed").prop("checked"))
			{
				error += (error != "") ? "<br />" : "";
				error += "Please agree to the terms and conditions";
			}

			if (error != "")
			{
				swal.fire({
					title: "Error",
					html: error,
					type: "error",
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					allowEscapeKey: false,
					closeOnClickOutside: false
				});

				return;
			}

			swal.fire({
				title: "Add Funds",
				text: "Are you sure you want to add funds?",
				type: "question",
				showCancelButton: true,
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				allowOutsideClick: false,
				allowEscapeKey: false,
				closeOnClickOutside: false
			})
			.then((result) =>
			{
				if (result.value)
				{
					obj.addFunds($("#currentOption").val(), $("#saveAmount").val(), $(this).data("random"));
				}
			});
		});

		$(document).on("click", ".copy", function()
		{
			$("#saveAmount").val($("#available").html());
		});

		$(document).on("click", ".close", function()
		{
			obj.closeModal();
		});

		$(".savings-info").unbind();
		$(document).on("click", ".savings-info", function()
		{
			swal.fire({
				title: $(this).data("ticker") + " Easy Save",
				text: $("#info" + $(this).data("info")).html(),
				type: "info",
				confirmButtonText: 'OK',
				allowOutsideClick: false,
				allowEscapeKey: false,
				closeOnClickOutside: false
			})
		});
	}

	closeModal()
	{
		$('.modal').modal("hide");
	}

	/** ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	 * Process requests
	 */

	addFunds(optionID, amount, random)
	{
		var obj = this;

		// show loading
		$("#addForm").hide();
		$("#addProcessing").show();
		$("#reportLoading").show();

		$.ajax(
		{
			url: "/services/savings/add-funds.php",
			data:
			{
				"optionID": optionID,
				"amount": amount,
				"random": random
			},
			type: "POST",
			success: function(data)
			{
				if (data.Error.Code == 0)
				{
					swal.fire({
						title: "Success",
						text: "Funds successfully added",
						type: "success",
						confirmButtonText: 'OK',
						allowOutsideClick: false,
						allowEscapeKey: false,
						closeOnClickOutside: false
					})
					.then((result) =>
					{
						if (result.value)
						{
							// show invested view
							$(".option" + optionID).closest("div").find(".joinoption").addClass("hide").removeClass("visible");
							$(".option" + optionID).closest("div").find(".existingoption").addClass("visible").removeClass("hide");

							// update capital amount
							var newCapital = parseFloat($(".option" + optionID).closest("div").find(".capital").data("original")) + parseFloat(amount);
							$(".option" + optionID).closest("div").find(".capital").data("original", newCapital)
							$(".option" + optionID).closest("div").find(".capital").html(newCapital.toFixed(8));

							// hide popup
							obj.closeModal();
						}
					});
				}
				else
				{
					Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");

					$("#addForm").show();
					$("#addProcessing").hide();
				}
			},
			error: function(xhr)
			{
				if (xhr.status == 429)
				{
					Misc.errorMessage("Too Many Requests");
				}
				else
				{
					Misc.errorMessage("Error Adding to Savings");

					$("#addForm").show();
					$("#addProcessing").hide();
				}
			}
		});
	}

	claimInterest(optionID, random)
	{
		var obj = this;

		$.ajax(
		{
			url: "/services/savings/claim-interest.php",
			data:
			{
				"optionID": optionID,
				"random": random
			},
			type: "POST",
			success: function(data)
			{
				if (data.Error.Code == 0)
				{
					swal.fire({
						title: "Success",
						text: "Rewards successfully claimed",
						type: "success",
						confirmButtonText: 'OK',
						allowOutsideClick: false,
						allowEscapeKey: false,
						closeOnClickOutside: false
					})
					.then((result) =>
					{
						if (result.value)
						{
							// update interest amount
							$(".option" + optionID).closest("div").find(".interest").data("total", 0);
							$(".option" + optionID).closest("div").find(".interest").html("0.00000000");
							$(".option" + optionID).closest("div").find(".interestequiv").data("total", 0);
							$(".option" + optionID).closest("div").find(".interestequiv").html("0.00000000");

							// hide popup
							obj.closeModal();
						}
					});
				}
				else
				{
					Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
				}
			},
			error: function(xhr)
			{
				if (xhr.status == 429)
				{
					Misc.errorMessage("Too Many Requests");
				}
				else
				{
					Misc.errorMessage("Error Claiming Rewards");
				}
			}
		});
	}

	claimExit(optionID, random)
	{
		var obj = this;

		$.ajax(
		{
			url: "/services/savings/claim-exit.php",
			data:
			{
				"optionID": optionID,
				"random": random
			},
			type: "POST",
			success: function(data)
			{
				if (data.Error.Code == 0)
				{
					swal.fire({
						title: "Success",
						text: "Capital & Rewards successfully claimed",
						type: "success",
						confirmButtonText: 'OK',
						allowOutsideClick: false,
						allowEscapeKey: false,
						closeOnClickOutside: false
					})
					.then((result) =>
					{
						if (result.value)
						{
							// show join view, hide invested
							$(".option" + optionID).closest("div").find(".existingoption").addClass("hide").removeClass("visible");
							$(".option" + optionID).closest("div").find(".joinoption").addClass("visible").removeClass("hide");

							// clear amount
							$(".option" + optionID).closest("div").find(".capital").data("original", 0)
							$(".option" + optionID).closest("div").find(".capital").html("0.00000000");
							$(".option" + optionID).closest("div").find(".interest").data("total", 0);
							$(".option" + optionID).closest("div").find(".interest").html("0.00000000");
							$(".option" + optionID).closest("div").find(".interestequiv").data("total", 0);
							$(".option" + optionID).closest("div").find(".interestequiv").html("0.00000000");

							// hide popup
							obj.closeModal();
						}
					});
				}
				else
				{
					Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
				}
			},
			error: function(xhr)
			{
				if (xhr.status == 429)
				{
					Misc.errorMessage("Too Many Requests");
				}
				else
				{
					Misc.errorMessage("Error Claiming and Exiting");
				}
			}
		});
	}

	calculateInterest(elm)
	{
		// animate
		var increment = 0.000000001;
		var total = parseFloat(elm.data("total"));

		var start = total - 0.0000004;
		if (total <= 0.0000004)
		{
			start = 0;
		}
		start = (start > 0) ? start : 0;

		var animation = setInterval(function()
		{
			if (start < total)
			{
				start += increment;
				elm.html(start.toFixed(8));
			}
			else
			{
				clearInterval(animation);
			}
		}, 1);
	}

}
