
class Loans
{

	/** ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	 * Events
	 */

	events()
	{
		var obj = this;

		// load page
		var url = document.location.toString();

	    if (url.match('#'))
	    {
	    	var type = url.split('#')[1].toUpperCase();

	    	if (type == "XZAR")
	    	{
	    		type = "xZAR";
	    	}

	    	// show deposit
	    	if (type != "")
	    	{
	    		obj.showDepositCollateral($('#sec' + type).find(".showDepositCollateral").data("id"));
	    	}
	    }

		// sliders
	    $(".slide").slider(
	    {
	    	value: 0,
	    	min: 0,
	    	max: 100,
	    	step: 1,
	        create: function()
	        {
	        	var optionID = $(this).data("id");
	        	var total = $(this).data("total");
				var used = $(this).data("used");
				var percent = Math.round((used / total) * 100);

				if (!isNaN(percent))
	        	{
					if (used > 0)
					{
						// this triggers change event
						$(".slider" + optionID).slider("option", "value", percent);
						$(".slideAmount" + optionID).text(percent + "%");

						obj.updateDisplaySlider(optionID, percent);
					}
	        	}
	        },
	    	slide: function(event, ui)
	    	{
	    		var optionID  = $(this).slider().data("id");
	        	var loanAvailable = parseFloat($(".slider" + optionID).data("total"));
	        	var loanUsed = parseFloat($(".slider" + optionID).data("used"));
	    		var selected = loanAvailable * (ui.value / 100);
	    		var borrow = selected - loanUsed;
	    		if (borrow < 0)
	    		{
	    			borrow = 0;
	    		}

	    		// minimum limits set to already used
	    		var min = Math.round((loanUsed / loanAvailable) * 100);
	    		if (ui.value < min)
	    		{
	    			// this triggers change event
	    			$(".slider" + optionID).slider("option", "value", min);
	    			return false;
	    		}

	    		// dont allow more than limit
	    		var limit  = $("#loanLimit" + optionID).val();
	    		if (ui.value > limit)
	    		{
	    			$(".maxBorrow" + optionID).show();
	    			return false;
	    		}
	    		else
	    		{
	    			$(".maxBorrow" + optionID).hide();
	    		}

	    		// update input
	    		$("#loanAmount" + optionID).val(borrow.toFixed(8));
	    		$(".ui-slider-handle").text("");
	    		$(".slideAmount" + optionID).text(ui.value + "%");

	    		// update slider
	    		return obj.updateDisplaySlider(optionID, ui.value);
	    	}
	    });

	    $(document).on("click", ".maxSlider", function()
		{
	    	var optionID  = $(this).data("id");
	    	var limit  = $("#loanLimit" + optionID).val();

	    	// set slider display
			$(".slider" + optionID).slider("option", "value", limit);
			$(".slider" + optionID).addClass("loanSliderWarningHigh");
			$(".slider" + optionID).removeClass("loanSliderWarning");
			$(".slideAmount" + optionID).text(limit + "%");
			$(".riskWarning" + optionID).text("High Risk");
			$(".maxBorrow" + optionID).show();

			var loanAvailable = parseFloat($(".slider" + optionID).data("total"));
			var loanAvailableMax = parseFloat(loanAvailable * (limit / 100));
        	var loanUsed = parseFloat($(".slider" + optionID).data("used"));
    		var borrow = loanAvailableMax - loanUsed;
    		if (borrow < 0)
    		{
    			borrow = 0;
    		}

    		$("#loanAmount" + optionID).val(borrow.toFixed(8));
		});

		// modal display
		$(".showDepositCollateral").unbind();
		$(document).on("click", ".showDepositCollateral", function(event)
		{
			var optionID = $(this).data("id");

			obj.showDepositCollateral(optionID);
		});

		$(".showWithdrawCollateral").unbind();
		$(document).on("click", ".showWithdrawCollateral", function()
		{
			var error = "";
			var optionID = $(this).data("id");

			$.ajax(
			{
				url: "/services/loans/show-withdraw.php",
				data:
				{
					"optionID": optionID
				},
				type: "POST",
				success: function(data)
				{
					if (data.Error.Code == 0)
					{
						$("#modalContainer").html(data.Modal);
						$("#modalLoansWithdraw").modal("show");
					}
					else
					{
						Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
					}
				},
				error: function(xhr)
				{
					if (xhr.status == 429)
					{
						Misc.errorMessage("Too Many Requests");
					}
					else
					{
						Misc.errorMessage("Error Showing Withdraw Collateral");
					}
				}
			});
		});

		$(".showRepayLoan").unbind();
		$(document).on("click", ".showRepayLoan", function()
		{
			var optionID = $(this).data("id");

			$.ajax(
			{
				url: "/services/loans/show-repay.php",
				data:
				{
					"optionID": optionID
				},
				type: "POST",
				success: function(data)
				{
					if (data.Error.Code == 0)
					{
						$("#modalContainer").html(data.Modal);
						$("#modalLoansRepay").modal("show");
					}
					else
					{
						Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
					}
				},
				error: function(xhr)
				{
					if (xhr.status == 429)
					{
						Misc.errorMessage("Too Many Requests");
					}
					else
					{
						Misc.errorMessage("Error Showing Repay Loan");
					}
				}
			});
		});

		// process user requests
		$("#depositCollateral").unbind();
		$(document).on("click", "#depositCollateral", function()
		{
			var error = "";
			var optionID = $("#option").val();
			var ticker = $("#ticker").val();
			var random = $(this).data("random");
			var currentCollateral = parseFloat(Misc.cleanNumber($("#currentCollateral").val()));
			var amount = parseFloat(Misc.cleanNumber($("#amount").val()));
			var minimum = parseFloat(Misc.cleanNumber($("#minimum").val()));
			var maximum = parseFloat(Misc.cleanNumber($("#maximum").val()));

			// validate
			if ((amount < minimum) ||
				(amount > maximum))
			{
				error = "Please enter an amount between<br />" + minimum + " - " + maximum + " " + ticker;
			}

			if (!$("#agreed").prop("checked"))
			{
				error += (error != "") ? "<br />" : "";
				error += "Please agree to the terms and conditions";
			}

			if ((currentCollateral + amount) > maximum)
			{
				error += (error != "") ? "<br />" : "";
				error += "Total deposited collateral allowed is " + maximum + " " + ticker;
			}

			if (error != "")
			{
				swal.fire({
					title: "Error",
					html: error,
					type: "error",
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					allowEscapeKey: false,
					closeOnClickOutside: false
				});

				return;
			}

			swal.fire({
				title: "Deposit Collateral",
				text: "Are you sure you want to deposit collateral?",
				type: "question",
				showCancelButton: true,
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				allowOutsideClick: false,
				allowEscapeKey: false,
				closeOnClickOutside: false
			})
			.then((result) =>
			{
				if (result.value)
				{
					obj.depositCollateral(optionID, amount, random);
				}
			});
		});

		$("#withdrawCollateral").unbind();
		$(document).on("click", "#withdrawCollateral", function()
		{
			var error = "";
			var optionID = $("#option").val();
			var ticker = $("#ticker").val();
			var random = $(this).data("random");
			var amount = parseFloat(Misc.cleanNumber($("#amount").val()));
			var minimum = parseFloat(0.01);
			var available = parseFloat(Misc.cleanNumber($("#available").val()));
			var outstanding = parseFloat(Misc.cleanNumber($("#collateralValueUsedPlusInterest" + optionID).val()));

			// validate
			if ((amount > available) ||
				(amount < minimum))
			{
				error = "Please enter an amount between<br />" + minimum + " - " + available + " " + ticker;
			}

			if (error != "")
			{
				swal.fire({
					title: "Error",
					html: error,
					type: "error",
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					allowEscapeKey: false,
					closeOnClickOutside: false
				});

				return;
			}

			var msg = "Are you sure you want to withdraw collateral?";
			if ((amount >= available) && (outstanding == 0))
			{
				msg = "Your loan will be closed and collateral deposited into your Spot Wallet.";
			}

			swal.fire({
				title: "Withdraw Collateral",
				text: msg,
				type: "question",
				showCancelButton: true,
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				allowOutsideClick: false,
				allowEscapeKey: false,
				closeOnClickOutside: false
			})
			.then((result) =>
			{
				if (result.value)
				{
					obj.withdrawCollateral(optionID, amount, random);
				}
			});
		});

		$(".takeLoan").unbind();
		$(document).on("click", ".takeLoan", function()
		{
			var error = "";
			var optionID = $(this).data("id");
			var random = $(this).data("random");
			var interestRate = $("#interestRate" + optionID).val();
			var ticker = $("#ticker" + optionID).val();
			var amount = parseFloat(Misc.cleanNumber($("#loanAmount" + optionID).val()));
			var minimum = parseFloat(Misc.cleanNumber($("#minimumLoan" + optionID).val()));
			var maximum = parseFloat(Misc.cleanNumber($("#maximumLoan" + optionID).val()));

			// validate
			if ((amount < minimum) ||
				(amount > maximum))
			{
				error = "Please enter an amount between<br />" + minimum + " - " + maximum + " " + ticker;
			}

			if (error != "")
			{
				swal.fire({
					title: "Error",
					html: error,
					type: "error",
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					allowEscapeKey: false,
					closeOnClickOutside: false
				});

				return;
			}

			swal.fire({
				title: "Take Loan",
				text: "I want to take a loan of " + amount.toFixed(8) + " " + ticker + " at a variable interest rate of " + interestRate + "%.",
				type: "question",
				showCancelButton: true,
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				allowOutsideClick: false,
				allowEscapeKey: false,
				closeOnClickOutside: false
			})
			.then((result) =>
			{
				if (result.value)
				{
					obj.takeLoan(optionID, amount, random);
				}
			});
		});

		$("#repay").unbind();
		$(document).on("click", "#repay", function()
		{
			var error = "";
			var optionID = $("#option").val();
			var ticker = $("#ticker").val();
			var random = $(this).data("random");
			var amount = parseFloat(Misc.cleanNumber($("#amount").val()));
			var minimum = parseFloat(Misc.cleanNumber($("#minimum").val()));
			var maximum = parseFloat(Misc.cleanNumber($("#maximum").val()));

			// validate
			if ((amount < minimum) ||
				(amount > maximum))
			{
				error = "Please enter an amount between<br />" + minimum + " - " + maximum + " " + ticker;
			}

			if (amount <= 0)
			{
				error = "Please enter an amount greater than 0";
			}

			if (error != "")
			{
				swal.fire({
					title: "Error",
					html: error,
					type: "error",
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					allowEscapeKey: false,
					closeOnClickOutside: false
				});

				return;
			}

			swal.fire({
				title: "Repay Loan",
				text: amount + " " + ticker + " will be withdrawn from your Spot Wallet to repay your loan",
				type: "warning",
				showCancelButton: true,
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				allowOutsideClick: false,
				allowEscapeKey: false,
				closeOnClickOutside: false
			})
			.then((result) =>
			{
				if (result.value)
				{
					obj.repayLoan(optionID, amount, random);
				}
			});
		});

		$(".closeWithdraw").unbind();
		$(document).on("click", ".closeWithdraw", function()
		{
			var error = "";
			var optionID = $(this).data("id");
			var random = $(this).data("random");
			var amount = parseFloat(Misc.cleanNumber($("#collateralValueUsedPlusInterest" + optionID).val()));

			// validate
			if (amount > 0)
			{
				error = "Please repay loan in full before closing";
			}

			if (error != "")
			{
				swal.fire({
					title: "Error",
					html: error,
					type: "error",
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					allowEscapeKey: false,
					closeOnClickOutside: false
				});

				return;
			}

			swal.fire({
				title: "Close & Withdraw Collateral",
				text: "Your loan will be closed and collateral deposited into your Spot Wallet.",
				type: "question",
				showCancelButton: true,
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				allowOutsideClick: false,
				allowEscapeKey: false,
				closeOnClickOutside: false
			})
			.then((result) =>
			{
				if (result.value)
				{
					obj.closeWithdrawCollateral(optionID, random);
				}
			});
		});

		// general currency input clean up
		$(".currencyInput").unbind();
		$(document).on('propertychange change paste keyup input', '.currencyInput', function()
		{
			// force only valid currency values
			$(this).val(Misc.cleanNumber($(this).val()));
		});

		// loan maximums
		$(".loanAmount").unbind();
		$(document).on('propertychange change paste keyup input', '.loanAmount', function()
		{
			var optionID = $(this).data("id");
	    	var amount = parseFloat($(this).val());
	    	var total = parseFloat($(".slider" + optionID).data("total"));
	    	var available = parseFloat($(this).data("available"));

			// force only valid currency values
			$(this).val(Misc.cleanNumber($(this).val()));

			// limit to available
			if (amount > available)
			{
				$(this).val(available);
				amount = available;
			}

			// calculate slider
			var percent = Math.round((amount / total) * 100);
			$(".slider" + optionID).slider("option", "value", percent);
    		$(".slideAmount" + optionID).text(percent + "%");

    		obj.updateDisplaySlider(optionID, percent);
		});

		$(document).on("click", ".copy", function()
		{
			$("#" + $(this).data("target")).val($(this).data("available"));
		});

		$(document).on("click", ".close", function()
		{
			obj.closeModal();
		});

		// info buttons
		$(".loans-info").unbind();
		$(document).on("click", ".loans-info", function()
		{
			swal.fire({
				title: "Easy Loans",
				text: $("#info" + $(this).data("info") + "_" +  $(this).data("option")).html(),
				type: "info",
				confirmButtonText: 'OK',
				allowOutsideClick: false,
				allowEscapeKey: false,
				closeOnClickOutside: false
			})
		});
	}

	/** ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	 * UI Control
	 */

	closeModal()
	{
		$('.modal').modal("hide");
	}

	showDepositCollateral(optionID)
	{
		$.ajax(
		{
			url: "/services/loans/show-deposit.php",
			data:
			{
				"optionID": optionID
			},
			type: "POST",
			success: function(data)
			{
				if (data.Error.Code == 0)
				{
					$("#modalContainer").html(data.Modal);
					$("#modalLoansDeposit").modal("show");
				}
				else
				{
					Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
				}
			},
			error: function(xhr)
			{
				if (xhr.status == 429)
				{
					Misc.errorMessage("Too Many Requests");
				}
				else
				{
					Misc.errorMessage("Error Showing Deposit Collateral");
				}
			}
		});
	}

	updateDisplay(optionID, summary)
	{
		// display fields in order of appearance
		$("#vwCollateralAmount" + optionID).html(Misc.formatCurrency(summary.collateralAmount, 8));

		$("#vwCollateralValueAvailable" + optionID).html(Misc.formatCurrency(summary.collateralValueAvailable, 2));
		$("#collateralValueAvailable" + optionID).html(summary.collateralValueAvailable);
		$("#loanAmount" + optionID).data("available", summary.collateralValueAvailable);

		$(".slider" + optionID).data("total", summary.collateralValue);
		$(".slider" + optionID).data("used", summary.collateralValueUsed);

		$("#vwCollateralValueUsed" + optionID).html(Misc.formatCurrency(summary.collateralValueUsed, 2));

		$("#vwLiquidationPrice" + optionID).html(Misc.formatCurrency(summary.liquidationPrice, 2));

		$("#vwCollateralPrice" + optionID).html(Misc.formatCurrency(summary.collateralPrice, 2));

		$("#vwWithdrawalAvailable" + optionID).html(Misc.formatCurrency(summary.withdrawalAmountAvailable, 8));

		$("#collateralValueUsedPlusInterest" + optionID).val(summary.collateralValueUsedPlusInterest);
		$("#vwCollateralValueUsedPlusInterest" + optionID).html(Misc.formatCurrency(summary.collateralValueUsedPlusInterest, 8));

		if (summary.collateralValueUsedPlusInterest > 0)
		{
			$("#closeWarning" + optionID).show();
			$("#closeWithdraw" + optionID).prop("disabled", true);
		}
		else
		{
			$("#closeWarning" + optionID).hide();
			$("#closeWithdraw" + optionID).prop("disabled", false);
		}

		// change slider
		this.updateDisplaySlider(optionID, summary.collateralValuePercentage);

		// change display view based on collateral amount
		if (summary.collateralAmount > 0)
		{
			if ($(".option" + optionID + " .joinoption").is(":visible"))
			{
				$(".option" + optionID + " .joinoption").fadeOut(500, function()
				{
					$(".option" + optionID + " .existingoption").fadeIn(500);
				});
			}
		}
		else
		{
			if ($(".option" + optionID + " .existingoption").is(":visible"))
			{
				$(".option" + optionID + " .existingoption").fadeOut(500, function()
				{
					$(".option" + optionID + " .joinoption").fadeIn(500);
				});
			}
		}

		// border highlight
		if (summary.collateralAmount > 0)
		{
			$(".optionBlock" + optionID).addClass($(".option" + optionID).data("section").toLowerCase() + "-border-highlight");
		}
		else
		{
			$(".optionBlock" + optionID).removeClass($(".option" + optionID).data("section").toLowerCase() + "-border-highlight");
		}

		// remove warning
		if (summary.collateralValueUsedPlusInterest <= parseFloat($("#liquidationPrice" + optionID).data("price")))
		{
			$("#liquidationWarning" + optionID).hide();
		}
		else
		{
			$("#liquidationWarning" + optionID).show();
		}
	}

	updateDisplaySlider(optionID, percent)
	{
		// adjust slider width and handle/marker positions


		// change handle color & status
		if (($("#liquidationRate" + optionID).val() > 0) && (percent >= $("#liquidationRate" + optionID).val()))
		{
			$(".slider" + optionID).removeClass("loanSliderWarning");
			$(".slider" + optionID).removeClass("loanSliderWarningHigh");
			$(".slider" + optionID).addClass("loanSliderLiquidated");
			$(".riskWarning" + optionID).text("Liquidated");
		}
		else if (percent >= 40)
		{
			$(".slider" + optionID).removeClass("loanSliderWarning");
			$(".slider" + optionID).addClass("loanSliderWarningHigh");
			$(".slider" + optionID).removeClass("loanSliderLiquidated");
			$(".riskWarning" + optionID).text("High Risk");
		}
		else if (percent >= 30)
		{
			$(".slider" + optionID).addClass("loanSliderWarning");
			$(".slider" + optionID).removeClass("loanSliderWarningHigh");
			$(".slider" + optionID).removeClass("loanSliderLiquidated");
			$(".riskWarning" + optionID).text("Medium Risk");
		}
		else
		{
			$(".slider" + optionID).removeClass("loanSliderWarning");
			$(".slider" + optionID).removeClass("loanSliderWarningHigh");
			$(".slider" + optionID).removeClass("loanSliderLiquidated");
			$(".riskWarning" + optionID).text("Low Risk");
		}

		if (percent >= 50)
		{
			$(".maxBorrow" + optionID).show();
		}
	}

	/** ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	 * Process requests
	 */

	depositCollateral(optionID, amount, random)
	{
		var obj = this;

		$.ajax(
		{
			url: "/services/loans/deposit-collateral.php",
			data:
			{
				"optionID": optionID,
				"amount": amount,
				"random": random
			},
			type: "POST",
			success: function(data)
			{
				if (data.Error.Code == 0)
				{
					swal.fire({
						title: "Success",
						text: "Collateral successfully deposited",
						type: "success",
						confirmButtonText: 'OK',
						allowOutsideClick: false,
						allowEscapeKey: false,
						closeOnClickOutside: false
					})
					.then((result) =>
					{
						if (result.value)
						{
							// reset slider - this triggers change event
			    			$(".slider" + optionID).slider("option", "value", data.Result.Summary.collateralValuePercentage);
			    			$("#loanAmount" + optionID).val(0);
			    			$(".ui-slider-handle").text("");
				    		$(".slideAmount" + optionID).text(data.Result.Summary.collateralValuePercentage + "%");

							// update display
							obj.updateDisplay(optionID, data.Result.Summary);

							// hide popup
							obj.closeModal();
						}
					});
				}
				else
				{
					Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
				}
			},
			error: function(xhr)
			{
				if (xhr.status == 429)
				{
					Misc.errorMessage("Too Many Requests");
				}
				else
				{
					Misc.errorMessage("Error depositing collateral");
				}
			}
		});
	}

	takeLoan(optionID, amount, random)
	{
		var obj = this;

		$.ajax(
		{
			url: "/services/loans/take-loan.php",
			data:
			{
				"optionID": optionID,
				"amount": amount,
				"random": random
			},
			type: "POST",
			success: function(data)
			{
				if (data.Error.Code == 0)
				{
					swal.fire({
						title: "Success",
						text: "Loan taken successfully",
						type: "success",
						confirmButtonText: 'OK',
						allowOutsideClick: false,
						allowEscapeKey: false,
						closeOnClickOutside: false
					})
					.then((result) =>
					{
						if (result.value)
						{
							// update display
							obj.updateDisplay(optionID, data.Result.Summary);

							// hide popup
							obj.closeModal();
						}
					});
				}
				else
				{
					Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
				}
			},
			error: function(xhr)
			{
				if (xhr.status == 429)
				{
					Misc.errorMessage("Too Many Requests");
				}
				else
				{
					Misc.errorMessage("Error taking loan");
				}
			}
		});
	}

	repayLoan(optionID, amount, random)
	{
		var obj = this;

		$.ajax(
		{
			url: "/services/loans/repay-loan.php",
			data:
			{
				"optionID": optionID,
				"amount": amount,
				"random": random
			},
			type: "POST",
			success: function(data)
			{
				if (data.Error.Code == 0)
				{
					swal.fire({
						title: "Success",
						text: "Loan repayment successfully",
						type: "success",
						confirmButtonText: 'OK',
						allowOutsideClick: false,
						allowEscapeKey: false,
						closeOnClickOutside: false
					})
					.then((result) =>
					{
						if (result.value)
						{
							// reset slider - this triggers change event
			    			$(".slider" + optionID).slider("option", "value", data.Result.Summary.collateralValuePercentage);
			    			$("#loanAmount" + optionID).val(0);
			    			$(".ui-slider-handle").text("");
				    		$(".slideAmount" + optionID).text(data.Result.Summary.collateralValuePercentage + "%");

							// update display
							obj.updateDisplay(optionID, data.Result.Summary);

							// hide popup
							obj.closeModal();
						}
					});
				}
				else
				{
					Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
				}
			},
			error: function(xhr)
			{
				if (xhr.status == 429)
				{
					Misc.errorMessage("Too Many Requests");
				}
				else
				{
					Misc.errorMessage("Error repaying loan");
				}
			}
		});
	}

	withdrawCollateral(optionID, amount, random)
	{
		var obj = this;

		$.ajax(
		{
			url: "/services/loans/withdraw-collateral.php",
			data:
			{
				"optionID": optionID,
				"amount": amount,
				"random": random
			},
			type: "POST",
			success: function(data)
			{
				if (data.Error.Code == 0)
				{
					swal.fire({
						title: "Success",
						text: "Collateral successfully withdrawn",
						type: "success",
						confirmButtonText: 'OK',
						allowOutsideClick: false,
						allowEscapeKey: false,
						closeOnClickOutside: false
					})
					.then((result) =>
					{
						if (result.value)
						{
							// update slider handle
							var percent = Math.round((data.Result.Summary.collateralValueUsedPlusInterest / data.Result.Summary.collateralValue) * 100);
							$(".slider" + optionID).slider("option", "value", percent);
							$(".slideAmount" + optionID).text(percent + "%");

							// update display
							obj.updateDisplay(optionID, data.Result.Summary);

							// hide popup
							obj.closeModal();
						}
					});
				}
				else
				{
					Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
				}
			},
			error: function(xhr)
			{
				if (xhr.status == 429)
				{
					Misc.errorMessage("Too Many Requests");
				}
				else
				{
					Misc.errorMessage("Error withdrawing collateral");
				}
			}
		});
	}

	closeWithdrawCollateral(optionID, random)
	{
		var obj = this;

		$.ajax(
		{
			url: "/services/loans/withdraw-exit.php",
			data:
			{
				"optionID": optionID,
				"random": random
			},
			type: "POST",
			success: function(data)
			{
				if (data.Error.Code == 0)
				{
					swal.fire({
						title: "Success",
						text: "Loan closed and collateral successfully withdrawn",
						type: "success",
						confirmButtonText: 'OK',
						allowOutsideClick: false,
						allowEscapeKey: false,
						closeOnClickOutside: false
					})
					.then((result) =>
					{
						if (result.value)
						{
							// update display
							obj.updateDisplay(optionID, data.Result.Summary);

							// update slider position
							$(".slider" + optionID).click();

							// hide popup
							obj.closeModal();
						}
					});
				}
				else
				{
					Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
				}
			},
			error: function(xhr)
			{
				if (xhr.status == 429)
				{
					Misc.errorMessage("Too Many Requests");
				}
				else
				{
					Misc.errorMessage("Error closing and withdrawing collateral");
				}
			}
		});
	}

	calculateInterest(elm)
	{
		// animate
		var increment = 0.000000001;
		var total = parseFloat(elm.data("total"));

		var start = total - 0.0000004;
		if (total <= 0.0000004)
		{
			start = 0;
		}
		start = (start > 0) ? start : 0;

		var animation = setInterval(function()
		{
			if (start < total)
			{
				start += increment;
				elm.html(start.toFixed(8));
			}
			else
			{
				clearInterval(animation);
			}
		}, 1);
	}

}
